/* slideTransition.scss */

//old page exiting, hides it underneath (bottom) 
.pageSlider-exit > .page {
    z-index: 1;
  }
  //new page entering, show it on top
  .pageSlider-enter > .page {
    z-index: 10;
  }
  //LEFT transition transform 100% to the right
  .pageSlider-enter.left > .page {
    transform: translate3d(100%, 0, 0);
  }
  //RIGHT transition transform -100% to the left
  .pageSlider-enter.right > .page {
    transform: translate3d(-100%, 0, 0);
  }
  //old exit left direction
  .pageSlider-exit.left > .page {
    transform: translate3d(-100%, 0, 0);
  }
  //old exit right direction 
  .pageSlider-exit.right > .page {
    transform: translate3d(100%, 0, 0);
  }
  //Enter is active (Page has finished transitioning) make it settle down 
  .pageSlider-enter.pageSlider-enter-active > .page {
    transform: translate3d(0, 0, 0);
    //Apply all transitions 
    transition: all 800ms ease-in-out;
  }