.pinpad-wrapper {
    // width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 55px);
    grid-template-rows: repeat(5, 55px);
    row-gap: 20px;
    column-gap: 20px;
}

.pinpad-wrapper>.pinpad-btn {
    background-color: var(--alt-color);
    display: flex;
    justify-content: center;
    align-items:  center;
    border: none;
    color: #fff;
    font-size: 2em;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    box-shadow: 0 1px #999;
}

.pinpad-wrapper>.pinpad-btn.empty {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.pinpad-wrapper > .pinpad-btn:active {
    background-color: rgba(60, 75, 100, .9);
    box-shadow: none;
    transform: translateY(2px);
}

.pinpad-wrapper > .pinpad-btn.erase {
    background-color: var(--danger-color);
    border: none;
    color: #fff;
    font-size: 1.3em;
    border-radius: 7px;
    cursor: pointer;
    outline: none;
    box-shadow: none;
}

.pinpad-wrapper > .pinpad-btn:active {
    background-color: rgba(60, 75, 100, .9);
    box-shadow: 0 5px #666;
    transform: translateY(2px);
}

.pinpad-wrapper > .pinpad-btn.correct {
    background-color: var(--warning-color);
    border: none;
    color: #fff;
    font-size: 1.3em;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    box-shadow: none;
}

.pinpad-wrapper > .pinpad-btn:active {
    background-color: rgba(60, 75, 100, .9);
    box-shadow: 0 5px #666;
    transform: translateY(2px);
}

.pinpad-wrapper > .pinpad-btn.success {
    background-color: var(--success-color);
    border: none;
    color: #fff;
    font-size: 1.3em;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    box-shadow: none;
}

.pinpad-wrapper > .pinpad-btn:active {
    background-color: rgba(60, 75, 100, .9);
    box-shadow: 0 5px #666;
    transform: translateY(2px);
}