.bottom-tabs{
  left: 50%;
  transform: translate(-50%, 0);
  position: fixed;
  bottom: 1rem;
  width: 95%;
}

.bottom-tabs-inner{
  background-color: #ffffff;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  padding: .5rem 1rem;
}

.bottom-tabs-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: $secondary;
}

.bottom-tabs-item.active{
  background-color: $primary;
  color: #ffffff;
}

.bottom-tabs-item span{
  font-size: 0.8rem;
}
